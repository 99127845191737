<template>
    <pageHeader />
    <div class="container">
        <img class="bg" src="@/assets/backg.jpg" alt="">
        <breadcrumb></breadcrumb>
        <img class="banner" src="@/assets/qifu/banner.png" alt="">
        <div class="banner-title">
            <!-- <p>NEEDS FUNDING</p> -->
            <p>{{ $t('siluqifutitle') }}</p>
            <p>SILK ROAD PUBLICK WELFARE DIGITAL BLESSING ACTIVITY</p>
        </div>

        <div class="list-box">
            <div class="header">
                <div class="title">
                    <img class="title-bg" src="@/assets/prayer/title.png" alt="">
                    <p :style="{ letterSpacing: lanuage === 'en' ? '0' : '15px' }">{{ $t('qifuhuodong') }}</p>
                </div>
                <div class="search">
                    <img src="@/assets/icon/search.png" alt="">
                    <input type="text" :placeholder="`(${$t('qingshuruguanjianzi')})`">
                </div>
            </div>
            <div class="list">
                <div class="item" v-for="(item, index) in prayerList" :key="index" @click="handleClick(item)">
                    <img :src="item?.picture" alt="">
                    <div class="info">
                        <p class="title">{{ item?.title }}</p>
                        <p class="desc">{{ item?.detail }}</p>
                        <div class="more">
                            {{ $t('liaojiegengduo') }}
                            <img src="@/assets/icon/arrow2.png" alt="">
                        </div>
                    </div>
                </div>
            </div>
            <!-- <pagination></pagination> -->
        </div>


        <!-- <div class="content">
            <div class="title">
                <img src="@/assets/qifu/title5.png" alt="">
                <p>{{ $t('liucheng') }}</p>
            </div>
            <div class="text">
                <p type="text" v-html="result.param1"/>
            </div>
            <div class="title">
                <img src="@/assets/qifu/title4.png" alt="">
                <p>{{ $t('canyufangshi') }}</p>
            </div>
            <div class="text">
                <p type="text" v-html="result.param2"/>
            </div>
            <div class="title">
                <img src="@/assets/qifu/title6.png" alt="">
                <p>{{ $t('shehuiyiyi') }}</p>
            </div>
            <div class="text">
                <p type="text" v-html="result.param3"/>
            </div>
        </div>
        
        <div class="list-box">
            <p class="title">{{ $t('liuchengtitle') }}</p>
            <shopContainer
                :shopList="shopList"
                :typeList="typeList"
                @onChange="handleChange"
                @onSearch="handleSearch"
                @clickItem="handleClickItem"
            />
            <pagination :lastPage="lastPage" @onChange="changePage"></pagination>
        </div> -->

        <!-- <enter/> -->
    </div>
    <pageFooter />
</template>

<!-- <script setup>
import {ref, onMounted} from 'vue'

import {getCommonApi} from "@/server/api"

onMounted(() => {
    getCommon()
})

const result = ref({})

async function getCommon() {
    const res = await getCommonApi({
        id: '7'
    })
    console.log('res', res);
    if (res.code === 200) {
        result.value = res.data
    }
}

</script> -->

<script>
import pageHeader from '@/components/pageHeader'
import pageFooter from '@/components/pageFooter'
import breadcrumb from '@/components/breadcrumb'
import shopContainer from '@/components/shopContainer'
import enter from "@/components/enter.vue"
import pagination from "@/components/pagination"
import { getDesignPageApi, getDesignDetailApi, getDesignTypeApi, getPrayerPageApi } from "@/server/api"

export default {
    components: {
        pageHeader,
        pageFooter,
        breadcrumb,
        shopContainer,
        enter,
        pagination
    },
    data() {
        return {
            searchContent: '',
            params: {
                type: '',
                state: '',
            },
            list: [],
            page: 1,
            lastPage: 0,
            shopList: [],
            typeList: [],
            museumList: [],
            prayerList: []
        }
    },
    mounted() {
        this.getDesignPage()
        this.getDesignType()

        this.getPrayerPage()
    },
    methods: {
        async getPrayerPage() {
            const res = await getPrayerPageApi({
                title: '',
                page: 1
            })
            if (res.code === 200) {
                this.prayerList = res.data.data
            }
        },

        handleClick(e) {
            this.$router.push({
                path: '/prayer/details',
                query: {
                    id: e.prayer_id
                }
            })
        },


        async getDesignPage() {
            const res = await getDesignPageApi({
                title: this.searchContent,
                page: this.page,
                size: 3,
                ...this.params
            })
            if (res.code === 200) {
                this.lastPage = res.data.last_page
                this.shopList = res.data.data
            }
        },
        async getDesignType() {
            const res = await getDesignTypeApi()
            console.log('res', res)
            if (res.code === 200) {
                this.typeList = res.data
            }
        },
        handleChange(e) {
            this.page = 1
            this.params[e.key] = e.value
            this.getDesignPage()
        },
        handleSearch(e) {
            this.page = 1
            this.searchContent = e
            this.getDesignPage()
        },
        handleClickItem(e) {
            this.$router.push({
                path: '/prayer/detail',
                query: {
                    id: e.design_id
                }
            })
        },
        changePage(e) {
            this.page = e
            this.getDesignPage()
        }
    }
}
</script>

<style lang="scss" scoped>
.container {
    position: relative;
    padding: 20px 0 120px;

    .bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: -1;
    }

    .banner {
        width: 100%;
        position: relative;
        margin-top: -176px;
        z-index: -1;
    }

    .banner-title {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: -80px;
        text-align: center;

        // p:first-child {
        //     font-size: 32px;
        //     background: #F9C230;
        //     border-radius: 30px;
        //     color: #000;
        //     padding: 4px 20px;
        //     margin-bottom: 20px;
        // }

        p:first-child {
            font-size: 70px;
            color: #fff;
            margin-bottom: 20px;
            font-weight: bold;
            letter-spacing: 15px;
            -webkit-text-stroke: 2px #fff;
        }

        p:nth-child(2) {
            font-size: 26px;
            color: #fff;
        }
    }

    .content,
    .list-box {
        margin: 116px auto 0;
        max-width: 1400px;

        .title {
            p {
                font-size: 66px;
                font-weight: bold;
                color: #ffe900;
                letter-spacing: 15px;
                margin-top: -90px;
                //-webkit-text-stroke: 2px #ffe900;
                white-space: pre;
            }

            .title-bg {
                width: 424px;
            }
        }

        .text {
            margin-top: 50px;
            margin-bottom: 100px;

            p {
                font-size: 28px;
                color: #F0F0F0;
                white-space: pre-wrap;
                //text-indent: 2em;
                margin-bottom: 20px;
                line-height: 44px;
            }
        }
    }

    .list-box {
        .header {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .search {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 440px;
                background: #2B2B2B;
                padding: 10px 20px;
                border-radius: 50px;
                border: 1px solid #872228;

                img {
                    width: 42px;
                    height: 42px;
                }

                input {
                    width: 100%;
                    padding: 0 15px;
                    background: transparent;
                    border: none;
                    border-left: 1px solid #fff;
                    height: 36px;
                    margin-left: 15px;
                    font-size: 32px;
                    color: #fff;

                    &:focus-visible {
                        outline: none;
                    }
                }
            }
        }

        .list {
            .item {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 120px;

                &:nth-child(even) {
                    flex-direction: row-reverse;
                }

                img {
                    width: 48%;
                    height: 338px;
                    border-radius: 10px;
                    object-fit: cover;
                }

                .info {
                    width: 48%;

                    .title {
                        font-size: 54px;
                        margin-bottom: 20px;
                        color: #fff;
                    }

                    .desc {
                        font-size: 28px;
                        color: #F0F0F0;
                        margin-bottom: 20px;
                    }

                    .more {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        width: 260px;
                        font-size: 24px;
                        background: linear-gradient(to right, #2A5BE9, #963FF0);
                        padding: 10px 20px;
                        border-radius: 30px;
                        color: #F0F0F0;
                        cursor: pointer;

                        img {
                            width: 36px;
                            height: 36px;
                            margin-left: 20px;
                        }
                    }
                }
            }
        }

    }
}
</style>