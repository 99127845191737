<template>
    <pageHeader/>
    <div class="container">
        <img class="bg" src="@/assets/backg.jpg" alt="">
        <breadcrumb></breadcrumb>
        <img class="banner" :src="museumDetail.main_picture" alt="">
        <div class="banner-title">
            <!-- <p>NEEDS FUNDING</p> -->
            <p>{{ museumDetail.title }}</p>
            <p>{{ museumDetail.detail }}</p>
        </div>
        <div class="content">
            <div class="title">
                <img :src="museumDetail.icon" alt="">
                <!-- <p>{{ $t('shehuiyiyi') }}</p> -->
            </div>
            <div class="text">
                <p type="text" v-html="museumDetail.content"/>
            </div>
        </div>

        <div class="list-box">
            <div class="header">
                <div class="title">
                    <img :src="museumDetail.topic" alt="">
                    <!-- <p :style="{letterSpacing: lanuage === 'en' ? '0' : '15px'}">{{ $t('tesewenhuaguan') }}</p> -->
                </div>
                <div class="search">
                    <img src="@/assets/icon/search.png" alt="">
                    <input type="text" :placeholder="`(${$t('bowuguanguanjianzi')})`">
                </div>
            </div>
            <div class="list">
                <template v-if="museumDetail.sub_style === 'Horizontal'">
                    <div
                        class="item-vertical"
                        v-for="(item,index) in museumList" :key="index"
                        @click="handleClick(item)"
                    >
                        <img :src="item.picture" alt="">
                        <p class="title">{{ item.title }}</p>
                        <p class="desc">{{ item.detail }}</p>
                    </div>
                </template>
                <template v-else>
                    <div
                        class="item-horizontal"
                        v-for="(item,index) in museumList" :key="index"
                        @click="handleClick(item)"
                    >
                        <img :src="item.picture" alt="">
                        <div class="info">
                            <p class="title">{{ item.title }}</p>
                            <p class="desc">{{ item.detail }}</p>
                            <div class="more">
                                {{ $t('liaojiegengduo') }}
                                <img src="@/assets/icon/arrow2.png" alt="">
                            </div>
                        </div>
                    </div>
                </template>
                
            </div>
        </div>

        <!-- <div class="list-box">
            <shopContainer
                :typeList="typeList"
                :shopList="shopList"
                type="price"
                @onChange="handleChange"
                @onSearch="handleSearch"
                @clickItem="handleClickItem"
            />
            <pagination :lastPage="lastPage" @onChange="changePage"></pagination>
        </div> -->

        <!-- <enter/> -->
    </div>
    <pageFooter/>
</template>

<script>
import pageHeader from '@/components/pageHeader'
import pageFooter from '@/components/pageFooter'
import breadcrumb from '@/components/breadcrumb'
import shopContainer from '@/components/shopContainer'
import enter from "@/components/enter.vue"
import pagination from "@/components/pagination"

import {getMuseumDetailApi, getMuseumDesignTypeApi, getMuseumDesignPageApi, getMuseumListApi} from "@/server/api"

export default {
    components: {
        pageHeader,
        pageFooter,
        breadcrumb,
        shopContainer,
        pagination,
        enter
    },
    data() {
        return {
            id: '',
            museumDetail: {},
            typeList: [],
            shopList: [],
            searchContent: '',
            params: {
                type: '',
                state: '',
            },
            list: [],
            page: 1,
            lastPage: 0,
            museumList: []
        }
    },
    mounted() {
        this.id = this.$route.query.id
        this.getMuseumList()
        this.getMuseumDesignPage()
        this.getMuseumDesignType()
        this.getMuseumDetail()
    },
    methods: {
        async getMuseumList() {
            const res = await getMuseumListApi({
                parent_museum_id: this.id
            })
            if (res.code === 200) {
                this.museumList = res.data
            }
        },
        handleClick(e) {
            this.$router.push({
                path: '/art/museums',
                query: {
                    id: e.museum_id,
                    style: this.museumDetail.sub_style
                }
            })
        },


        async getMuseumDetail() {
            const res = await getMuseumDetailApi({
                id: this.id
            })
            if (res.code === 200) {
                this.museumDetail = res.data
            }
        },
        async getMuseumDesignType() {
            const res = await getMuseumDesignTypeApi()
            if (res.code === 200) {
                this.typeList = res.data
            }
        },
        async getMuseumDesignPage() {
            const res = await getMuseumDesignPageApi({
                museum_id: this.id,
                title: this.searchContent,
                page: this.page,
                size: 3,
                ...this.params
            })
            if (res.code === 200) {
                this.lastPage = res.data.last_page
                this.shopList = res.data.data
            }
        },
        handleChange(e) {
            this.page = 1
            this.params[e.key] = e.value
            this.getMuseumDesignPage()
        },
        handleSearch(e) {
            console.log('e', e);
            this.page = 1
            this.searchContent = e
            this.getMuseumDesignPage()
        },
        changePage(e) {
            this.page = e
            this.getMuseumDesignPage()
        },
        // handleClickItem(e) {
        //     this.$router.push({
        //         path: '/art/museums/detail',
        //         query: {
        //             id: e.design_id
        //         }
        //     })
        // }
    }
}
</script>

<style lang="scss" scoped>
.container {
    position: relative;
    padding: 20px 0 120px;

    .bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: -1;
    }

    .banner {
        width: 100%;
        position: relative;
        margin-top: -176px;
        z-index: -1;
    }

    .banner-title {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: -150px;
        text-align: center;

        // p:first-child {
        //     font-size: 32px;
        //     background: #F9C230;
        //     border-radius: 30px;
        //     color: #000;
        //     padding: 4px 20px;
        //     margin-bottom: 20px;
        // }

        p:first-child {
            font-size: 56px;
            color: #fff;
            margin-bottom: 20px;
            font-weight: bold;
            letter-spacing: 15px;
            -webkit-text-stroke: 2px #fff;
        }

        p:nth-child(2) {
            font-size: 26px;
            color: #fff;
        }
    }

    .content {
        margin: 200px auto 0;
        max-width: 1400px;

        .title {
            p {
                font-size: 66px;
                font-weight: bold;
                color: #ffe900;
                letter-spacing: 15px;
                margin-top: -90px;
                //-webkit-text-stroke: 2px #ffe900;
                white-space: pre;
            }
        }

        .text {
            margin-top: 50px;
            margin-bottom: 100px;

            p {
                font-size: 28px;
                color: #F0F0F0;
                white-space: pre-wrap;
                //text-indent: 2em;
                margin-bottom: 20px;
                line-height: 44px;
            }
        }
    }

    .list-box {
        margin: 200px auto;
        max-width: 1400px;

        .title {
            p {
                font-size: 66px;
                font-weight: bold;
                color: #ffe900;
                letter-spacing: 15px;
                margin-top: -90px;
                //-webkit-text-stroke: 2px #ffe900;
                white-space: pre;
            }
        }

        .text {
            margin-top: 50px;
            margin-bottom: 100px;

            p {
                font-size: 28px;
                color: #F0F0F0;
                white-space: pre-wrap;
                //text-indent: 2em;
                margin-bottom: 20px;
                line-height: 44px;
            }
        }
    }

    .list-box {
        margin: 200px auto 0;

        .header {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .search {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 440px;
                background: #2B2B2B;
                padding: 10px 20px;
                border-radius: 50px;
                border: 1px solid #872228;

                img {
                    width: 42px;
                    height: 42px;
                }

                input {
                    width: 100%;
                    padding: 0 15px;
                    background: transparent;
                    border: none;
                    border-left: 1px solid #fff;
                    height: 36px;
                    margin-left: 15px;
                    font-size: 32px;
                    color: #fff;

                    &:focus-visible {
                        outline: none;
                    }
                }
            }
        }

        .list {
            .item-horizontal {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 120px;

                &:nth-child(even) {
                    flex-direction: row-reverse;
                }

                img {
                    width: 48%;
                    height: 338px;
                    border-radius: 10px;
                    object-fit: cover;
                }

                .info {
                    width: 48%;

                    .title {
                        font-size: 54px;
                        margin-bottom: 20px;
                        color: #fff;
                    }

                    .desc {
                        font-size: 28px;
                        color: #F0F0F0;
                        margin-bottom: 20px;
                    }

                    .more {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        width: 260px;
                        font-size: 24px;
                        background: linear-gradient(to right, #2A5BE9, #963FF0);
                        padding: 10px 20px;
                        border-radius: 30px;
                        color: #F0F0F0;
                        cursor: pointer;

                        img {
                            width: 36px;
                            height: 36px;
                            margin-left: 20px;
                        }
                    }
                }
            }

            .item-vertical {
                width: 28%;
                background: rgba(#000000, 0.3);
                padding: 20px;
                border-radius: 20px;
                margin: 20px calc((100% - (28% + 40px) * 3) / 3 / 2);

                img{
                    width: 100%;
                    // height: 100%;
                    border-radius: 20px;
                }
                .title{
                    font-size: 40px;
                    color: #fff;
                    margin-top: 20px;
                }   
                .desc{
                    display: flex;
                    align-items: center;
                    color: #fff;
                    font-size: 24px;
                    margin-top: 10px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }
            }
        }

    }
}
</style>