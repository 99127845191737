<template>
    <pageHeader />
    <div class="container">
        <img class="bg" src="@/assets/backg.jpg" alt="">
        <breadcrumb></breadcrumb>
        <detailHeader v-if="headerStyle === 'Horizontal'" :info="museumDetail" address :showAdd="false"
            :showTime="false" />
        <template v-else>
            <img class="banner" :src="museumDetail.main_picture" alt="">
            <div class="banner-title">
                <!-- <p>NEEDS FUNDING</p> -->
                <p>{{ museumDetail.title }}</p>
                <p>{{ museumDetail.detail }}</p>
            </div>
        </template>

        <div class="content">
            <div class="title">
                <img :src="museumDetail.icon" alt="">
                <!-- <p>{{ $t('shehuiyiyi') }}</p> -->
            </div>
            <div class="text">
                <p type="text" v-html="museumDetail.content" />
            </div>
        </div>

        <div class="list-box">
            <shopContainer :typeList="typeList" :shopList="shopList" :modeList="modeList"
                :type="museumDetail.is_show_price ? 'price' : ''" @onChange="handleChange" @onSearch="handleSearch"
                @clickItem="handleClickItem" />
            <pagination :lastPage="lastPage" @onChange="changePage"></pagination>
        </div>

        <!-- <enter/> -->
    </div>
    <pageFooter />
</template>

<script>
import pageHeader from '@/components/pageHeader'
import pageFooter from '@/components/pageFooter'
import breadcrumb from '@/components/breadcrumb'
import shopContainer from '@/components/shopContainer'
import enter from "@/components/enter.vue"
import pagination from "@/components/pagination"
import detailHeader from '@/components/detailHeader'

import { getMuseumDetailApi, getMuseumDesignTypeApi, getMuseumDesignModeApi, getMuseumDesignPageApi } from "@/server/api"

export default {
    components: {
        pageHeader,
        pageFooter,
        breadcrumb,
        shopContainer,
        pagination,
        enter,
        detailHeader
    },
    data() {
        return {
            id: '',
            museumDetail: {},
            typeList: [],
            modeList: [],
            shopList: [],
            searchContent: '',
            params: {
                type: '',
                state: '',
                mode: ''
            },
            list: [],
            page: 1,
            lastPage: 0,
            museumList: [],
            headerStyle: ''
        }
    },
    mounted() {
        this.id = this.$route.query.id
        this.headerStyle = this.$route.query.style
        this.getMuseumDesignPage()
        this.getMuseumDesignType()
        this.getMuseumDesignMode()
        this.getMuseumDetail()
    },
    methods: {
        async getMuseumDetail() {
            const res = await getMuseumDetailApi({
                id: this.id
            })
            if (res.code === 200) {
                this.museumDetail = res.data
            }
        },
        async getMuseumDesignType() {
            const res = await getMuseumDesignTypeApi()
            if (res.code === 200) {
                this.typeList = res.data
            }
        },
        async getMuseumDesignMode() {
            const res = await getMuseumDesignModeApi()
            if (res.code === 200) {
                this.modeList = res.data
            }
        },
        async getMuseumDesignPage() {
            const res = await getMuseumDesignPageApi({
                museum_id: this.id,
                title: this.searchContent,
                page: this.page,
                size: 3,
                ...this.params
            })
            if (res.code === 200) {
                this.lastPage = res.data.last_page
                this.shopList = res.data.data
            }
        },
        handleChange(e) {
            this.page = 1
            this.params[e.key] = e.value
            this.getMuseumDesignPage()
        },
        handleSearch(e) {
            console.log('e', e)
            this.page = 1
            this.searchContent = e
            this.getMuseumDesignPage()
        },
        changePage(e) {
            this.page = e
            this.getMuseumDesignPage()
        },
        handleClickItem(e) {
            this.$router.push({
                path: '/art/museums/detail',
                query: {
                    id: e.design_id
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.container {
    position: relative;
    padding: 20px 0 120px;

    .bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: -1;
    }

    .banner {
        width: 100%;
        position: relative;
        margin-top: -176px;
        z-index: -1;
    }

    .banner-title {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: -150px;
        text-align: center;

        // p:first-child {
        //     font-size: 32px;
        //     background: #F9C230;
        //     border-radius: 30px;
        //     color: #000;
        //     padding: 4px 20px;
        //     margin-bottom: 20px;
        // }

        p:first-child {
            font-size: 56px;
            color: #fff;
            margin-bottom: 20px;
            font-weight: bold;
            letter-spacing: 15px;
            -webkit-text-stroke: 2px #fff;
        }

        p:nth-child(2) {
            font-size: 26px;
            color: #fff;
        }
    }

    .content {
        margin: 200px auto 0;
        max-width: 1400px;

        .title {
            p {
                font-size: 66px;
                font-weight: bold;
                color: #ffe900;
                letter-spacing: 15px;
                margin-top: -90px;
                //-webkit-text-stroke: 2px #ffe900;
                white-space: pre;
            }
        }

        .text {
            margin-top: 50px;
            margin-bottom: 100px;

            p {
                font-size: 28px;
                color: #F0F0F0;
                white-space: pre-wrap;
                //text-indent: 2em;
                margin-bottom: 20px;
                line-height: 44px;
            }
        }
    }

    .list-box {
        margin: 200px auto;
        max-width: 1400px;

        .title {
            p {
                font-size: 66px;
                font-weight: bold;
                color: #ffe900;
                letter-spacing: 15px;
                margin-top: -90px;
                //-webkit-text-stroke: 2px #ffe900;
                white-space: pre;
            }
        }
    }
}
</style>