import request from "./request.js";

// 登录
export const loginPwdApi = (data) => {
  return request({
    url: "api/login/login_passwd",
    method: "post",
    data,
  });
};

export const loginSmsApi = (data) => {
  return request({
    url: "api/login/login",
    method: "post",
    data,
  });
};

export const registerApi = (data) => {
  return request({
    url: "api/login/register",
    method: "post",
    data,
  });
};

export const registerSmsSendApi = (data) => {
  return request({
    url: "api/login/register_sms_send",
    method: "post",
    data,
  });
};

export const loginSmsSendApi = (data) => {
  return request({
    url: "api/login/login_sms_send",
    method: "post",
    data,
  });
};

export const forgetSmsSendApi = (data) => {
  return request({
    url: "api/login/forget_sms_send",
    method: "post",
    data,
  });
};

export const setPassworddApi = (data) => {
  return request({
    url: "api/login/forget_set_password",
    method: "post",
    data,
  });
};

export const getUserInfoApi = (params) => {
  return request({
    url: "api/user/info",
    method: "get",
    params,
  });
};

export const getNoticeApi = (params) => {
  return request({
    url: "api/user/notice",
    method: "get",
    params,
  });
};

export const getEmailApi = (params) => {
  return request({
    url: "api/user/mail",
    method: "get",
    params,
  });
};

export const getAvatarListApi = (params) => {
  return request({
    url: "api/avatar/list",
    method: "get",
    params,
  });
};

export const getPartnersListApi = (params) => {
  return request({
    url: "api/partners/list",
    method: "get",
    params,
  });
};

export const updateUserInfoApi = (params) => {
  return request({
    url: "api/user/edit",
    method: "post",
    params,
  });
};

// 首页
export const getCultureImageListApi = (params) => {
  return request({
    url: "api/culture/page",
    method: "get",
    params,
  });
};

// 善缘宇宙
export const getNftTypeApi = (params) => {
  return request({
    url: "api/nft/type",
    method: "get",
    params,
  });
};
export const getNftPageApi = (params) => {
  return request({
    url: "api/nft/page",
    method: "get",
    params,
  });
};
export const getNftDetailApi = (params) => {
  return request({
    url: "api/nft/detail",
    method: "get",
    params,
  });
};

// 社会责任
export const getSocialListApi = (params) => {
  return request({
    url: "api/social/list",
    method: "get",
    params,
  });
};
// 社会责任
export const getHotspotListApi = (params) => {
  return request({
    url: "api/hotspot/list",
    method: "get",
    params,
  });
};
export const getSocialDetailApi = (params) => {
  return request({
    url: "api/social/detail",
    method: "get",
    params,
  });
};
export const getHotspotDetailApi = (params) => {
  return request({
    url: "api/hotspot/detail",
    method: "get",
    params,
  });
};

// 文化使节
export const getCultureListApi = (params) => {
  return request({
    url: "api/culture/page",
    method: "get",
    params,
  });
};
export const getCultureDetailApi = (params) => {
  return request({
    url: "api/culture/detail",
    method: "get",
    params,
  });
};

export const getCulturePageApi = (params) => {
  return request({
    url: "api/culture/design/page",
    method: "get",
    params,
  });
};
export const getCultureDesignDetailApi = (params) => {
  return request({
    url: "api/culture/design/detail",
    method: "get",
    params,
  });
};
export const getCultureTypeApi = (params) => {
  return request({
    url: "api/culture/design/type",
    method: "get",
    params,
  });
};

// 祈福活动
export const getDesignPageApi = (params) => {
  return request({
    url: "api/get_design/page",
    method: "get",
    params,
  });
};
export const getDesignDetailApi = (params) => {
  return request({
    url: "api/get_design/detail",
    method: "get",
    params,
  });
};
export const getDesignTypeApi = (params) => {
  return request({
    url: "api/get_design/type",
    method: "get",
    params,
  });
};

// 数字艺术馆
export const getMuseumListApi = (params) => {
  return request({
    url: "api/museum/list",
    method: "get",
    params,
  });
};
export const getMuseumDetailApi = (params) => {
  return request({
    url: "api/museum/detail",
    method: "get",
    params,
  });
};

export const getMuseumDesignTypeApi = (params) => {
  return request({
    url: "api/museum/design/type",
    method: "get",
    params,
  });
};

export const getMuseumDesignModeApi = (params) => {
  return request({
    url: "/api/museum/design/mode",
    method: "get",
    params,
  });
};

export const getMuseumDesignPageApi = (params) => {
  return request({
    url: "api/museum/design/page",
    method: "get",
    params,
  });
};
export const getMuseumDesignDetailApi = (params) => {
  return request({
    url: "api/museum/design/detail",
    method: "get",
    params,
  });
};

export const getCommonApi = (params) => {
  return request({
    url: "api/common/get",
    method: "get",
    params,
  });
};

export const getPrayerPageApi = (params) => {
  return request({
    url: "/api/prayer/page",
    method: "get",
    params,
  });
};

export const getPrayerDetailApi = (params) => {
  return request({
    url: "/api/prayer/detail",
    method: "get",
    params,
  });
};
