<template>
    <div>
        <div class="container">
            <div class="filter">
                <div style="display: flex; align-items: center;">
                    <template v-for="item in list" :key="item.label">
                        <div class="filter-item">
                            <div class="item">
                                <input class="item-input" type="text" @focus="item.showSelect = true"
                                       @blur="handleBlur(item)">
                                <p class="label">{{ item.label }}</p>
                                <div class="content">
                                    <p class="value">{{ item.value }}</p>
                                    <img src="@/assets/icon/select.png" alt="">
                                </div>
                            </div>
                            <div class="select-list" v-if="item.showSelect">
                                <p class="select-item" v-for="sel in item.data" :key="sel.id"
                                   @click="handleSelectItem(item, sel)">{{ sel.title }}</p>
                            </div>
                        </div>
                    </template>
                </div>
                <div class="search">
                    <img src="@/assets/icon/search.png" alt="">
                    <input v-model="searchContent" type="text" :placeholder="`(${$t('placeholder')})`"
                           @input="handleInput">
                </div>
            </div>

            <div class="list">
                <div class="list-item" v-for="(item, index) in shopList" :key="index" @click="clickItem(item)">
                    <div class="status-box">
                        <div class="time" :style="{ opacity: item.date ? '1' : '0' }">
                            <img src="@/assets/icon/time.png" alt="">
                            {{ item.date }}
                        </div>
                        <div class="status" :style="statusStyle(item.state)">{{ statusMap(item.state) }}</div>
                    </div>
                    <div class="picture">
                        <img :src="item.picture" alt="">
                    </div>
                    <p class="title"> {{ item.title }}</p>
                    <div class="desc">
                        <img v-if="item.icon" :src="item.icon" alt="">
                        <img v-else src="@/assets/art/avatar.png" alt="">
                        {{ item.name }}
                    </div>
                    <div class="info" v-if="type === 'price'">
                        <div class="tags">
                            <div class="tag" v-if="item.mode > 0">{{ modeMap(item.mode) }}</div>
                            <div class="tag" v-if="item.type > 0">{{ typeMap(item.type) }}</div>
                            <div class="num">{{ item.number }}{{ $t('fen') }}</div>
                        </div>
                        <div class="zs price">￥ {{ item.price }}</div>
                    </div>
                    <div class="info" v-else>
                        <div class="tags">
                            <div class="tag" v-if="item.mode > 0">{{ modeMap(item.mode) }}</div>
                            <div class="tag" v-if="item.type > 0">{{ typeMap(item.type) }}</div>
                        </div>
                        <div class="zs">
                            <img src="@/assets/qifu/zsIcon.png" alt="">
                            <p>{{ item.number }}{{ $t('fen') }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {statusMap, statusStyle} from "@/utils/index.js"
import {debounce} from "@/utils/index"

export default {
    props: {
        type: {
            type: String,
            default: ''
        },
        shopList: {
            type: Array,
            default: []
        },
        typeList: {
            type: Array,
            default: []
        },
        modeList: {
            type: Array,
            default: []
        }
    },
    data() {
        return {
            searchContent: '',
            list: [
                {
                    label: this.$t('fangshi'),
                    value: this.$t('quanbu'),
                    key: 'mode',
                    role: 'price',
                    showSelect: false,
                    data: [
                        {title: this.$t('quanbu'), id: ''},
                        {title: this.$t('xianshanghuodong'), id: '1'},
                        {title: this.$t('xianxiahuodong'), id: '2'},
                        {title: this.$t('liandonghuodong'), id: '3'}
                    ]
                }, {
                    label: this.$t('zhuangtai'),
                    value: this.$t('quanbu'),
                    key: 'state',
                    showSelect: false,
                    data: [
                        {title: this.$t('quanbu'), id: ''},
                        {title: this.$t('weikaishi'), id: '1'},
                        {title: this.$t('jinxingzhong'), id: '2'},
                        {title: this.$t('yijieshu'), id: '3'}
                    ]
                }, {
                    label: this.$t('leixing'),
                    value: this.$t('quanbu'),
                    key: 'type',
                    showSelect: false,
                    data: [
                        {title: this.$t('quanbu'), id: ''},
                    ]
                }
            ],
        }
    },
    computed: {
        statusMap() {
            return statusMap
        },
        statusStyle() {
            return statusStyle
        },
        modeMap() {
            return (e) => {
                let item = null

                if (this.modeList.length > 1) {
                    item = this.modeList.filter(item => item.id == e)
                } else {
                    item = {
                        '1': this.$t('xianshanghuodong'),
                        '2': this.$t('xianxiahuodong'),
                        '3': this.$t('liandonghuodong'),
                    }
                    return item[e];
                }
                return item[0]?.title
            }
        },
        typeMap() {
            return (e) => {
                const item = this.typeList.filter(item => item.id == e)
                return item[0]?.title
            }
        }
    },
    watch: {
        typeList(newVal) {
            this.list[2].data = [
                {title: this.$t('quanbu'), id: ''},
                ...newVal
            ]
        },
        modeList(newVal) {
            this.list[0].data = [
                {title: this.$t('quanbu'), id: ''},
                ...newVal
            ]
        }
    },
    methods: {
        handleBlur(item) {
            setTimeout(() => {
                item.showSelect = false
            }, 200)
        },
        handleSelectItem(item, subItem) {
            item.value = subItem.title
            this.$emit('onChange', {
                key: item.key,
                value: subItem.id
            })
        },
        handleInput() {
            this.page = 1
            debounce(() => {
                this.$emit('onSearch', this.searchContent)
            }, 300)
        },
        clickItem(item) {
            this.$emit('clickItem', item)
        }
    }
}
</script>

<style lang="scss" scoped>
.container {
    // padding: 0 80px;
}

.filter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    margin: 0 80px;
    border-bottom: 1px solid #E5E5E5;
    background: rgba(#872228, 0.2);
    border: 1px solid #872228;
    border-radius: 50px;
    position: relative;
    z-index: 99;

    .filter-item {
        position: relative;
        width: 320px;

        .item {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            background: #872228;
            padding: 10px 20px;
            margin-right: 20px;
            border-radius: 50px;
            font-size: 32px;
            color: #fff;
            cursor: pointer;
            z-index: 2;

            .item-input {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                opacity: 0;
                border-radius: 50px;
                cursor: pointer;
            }

            .content {
                display: flex;
                align-items: center;

                img {
                    width: 40px;
                    height: 40px;
                    margin-left: 10px;
                }
            }
        }

        .select-list {
            position: absolute;
            top: 20px;
            left: 0px;
            width: calc(100% - 21px);
            background: #2B2B2B;
            padding: 50px 0 10px;
            border-radius: 20px;
            border: 1px solid #872228;
            z-index: 1;

            .select-item {
                text-align: center;
                padding: 10px 0;
                font-size: 32px;
                color: #fff;
                cursor: pointer;

                &:hover {
                    color: #F9C230;
                }
            }
        }
    }

    .search {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 440px;
        background: #2B2B2B;
        padding: 10px 20px;
        border-radius: 50px;
        border: 1px solid #872228;

        img {
            width: 42px;
            height: 42px;
        }

        input {
            width: 100%;
            padding: 0 15px;
            background: transparent;
            border: none;
            border-left: 1px solid #fff;
            height: 36px;
            margin-left: 15px;
            font-size: 32px;
            color: #fff;

            &:focus-visible {
                outline: none;
            }
        }
    }
}

.list {
    display: flex;
    flex-wrap: wrap;
    max-width: 1760px;
    margin: 0 auto;

    .list-item {
        position: relative;
        width: 28%;
        background: rgba(#000000, 0.3);
        padding: 20px;
        border-radius: 20px;
        margin: 20px calc((100% - (28% + 40px) * 3) / 3 / 2);

        .status-box {
            position: absolute;
            width: calc(100% - 60px);
            top: 40px;
            left: 50%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            transform: translateX(-50%);
            z-index: 9;

            .status {
                padding: 4px 20px;
                border-radius: 20px;
                background: rgba(#000000, 0.6);
                font-size: 24px;
                border: 1px solid #A3FFB9;
                color: #A3FFB9;
            }

            .time {
                display: flex;
                align-items: center;
                color: #F0F0F0;
                padding: 10px 20px;
                background: rgba(#000000, 0.6);
                border-radius: 30px;
                font-size: 24px;

                img {
                    width: 20px;
                    margin-right: 10px;
                }
            }
        }

        .picture {
            position: relative;
            width: 100%;
            height: 0px;
            padding-top: 100%;

            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border-radius: 20px;
                object-fit: cover;
            }
        }

        .title {
            font-size: 40px;
            color: #fff;
            margin-top: 20px;
        }

        .desc {
            display: flex;
            align-items: center;
            color: #fff;
            font-size: 24px;
            margin-top: 10px;

            img {
                width: 48px;
                height: 48px;
                margin-right: 10px;
                border-radius: 50%;
            }
        }

        .info {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 20px;

            .tags {
                display: flex;
                align-items: center;

                .tag {
                    border: 1px solid #F9C230;
                    padding: 10px 14px;
                    border-radius: 30px;
                    color: #F9C230;
                    margin-right: 10px;
                    font-size: 20px;
                }

                .num {
                    font-size: 20px;
                    color: #F9C230;
                }
            }

            .zs {
                display: flex;
                align-items: center;
                justify-content: space-between;
                background: linear-gradient(to right, #2A5BE9, #963FF0);
                padding: 5px 25px;
                border-radius: 30px;
                font-size: 24px;
                color: #F9EBCA;

                img {
                    width: 40px;
                    height: 37px;
                    margin-right: 20px;
                }
            }

            .price {
                font-size: 30px;
            }
        }
    }
}
</style>